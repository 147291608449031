import React from "react";
import { Link } from "react-router-dom"
import {
    useIsAuthenticated,
    useMsal,
    AuthenticatedTemplate,
    UnauthenticatedTemplate
} from "@azure/msal-react";

function AuthPage() {
    const [getIdentity, setIdentity] = React.useState()
    const [getStatus, setStatus] = React.useState()
    const [getData, setData] = React.useState()

    const { instance, accounts } = useMsal();

    const loginRequest = {
        scopes: ["User.Read"]
    };

    const SignIn = () => {
        instance.loginPopup(loginRequest)
            .catch((e) => {
                console.log(e);
            });
    }

    const SignOut = () => {
        instance.logoutPopup({
            postLogoutRedirectUri: "/",
            mainWindowRedirectUri: "/",
        })
    }

    const fetchUri = (accessToken) => {
        let headers = new Headers()
        headers.append("Authorization", 'Bearer ' + accessToken);

        const options = {
            method: "GET",
            headers: headers
        };

        return fetch('https://graph.microsoft.com/v1.0/me', options)
            .then(response => response.json())
            .then(result => {
                console.log('[Fetch result]', result)
                setStatus("WooHoo!")
                setData(result)
            })
            .catch(error => console.log(error));
    }

    const RequestUri = () => {
        instance.acquireTokenSilent(
            {
                ...loginRequest,
                account: accounts[0],
            })
            .then((response) => {
                setStatus('Got Token')
                fetchUri(response.accessToken)
            });
    }

    const isAuthenticated = useIsAuthenticated();

    React.useEffect(() => {
        if (!getIdentity) {
            fetch('/.auth/me')
                .then((r) => {
                    if (!r.ok) {
                        throw new Error('Response Status: ${r.status}')
                    }
                    return r.json()
                })
                .then(data => {
                    setIdentity(data)
                    setStatus('Logged In!')
                    console.log('[fetch ok]', data)
                })
                .catch(err => {
                    console.error('[fetch error]', err)
                    setStatus('[Error] ' + err)
                })
            setStatus('Logging in...')
        }
    }, [getIdentity])

    let items = []

    items.push(<div>Status: {getStatus}</div>)

    items.push(<hr />)

    items.push(
        <div>
            {isAuthenticated ? 'Auth' : 'Not Auth'}
        </div>
    )

    items.push(
        <div>
            <AuthenticatedTemplate>
                <span className="btn btn-info" onClick={RequestUri}>Request</span>
                {getData && <div>
                    {getData.displayName}
                </div>
                }
                <div>
                    <a href='#' onClick={SignOut}>SignOut</a>
                </div>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <div>
                    <a href='#' onClick={SignIn}>SignIn</a>
                </div>
            </UnauthenticatedTemplate>
        </div>
    )

    items.push(<hr />)

    items.push(<div>
        This page is Authenticated as [{getIdentity?.clientPrincipal?.userDetails}]
    </div>)

    items.push(<div>
        <a href="/.auth/login"
            onClick={() => { setIdentity() }}
        >Login</a>
    </div>)

    items.push(<div>
        <a href="/.auth/logout"
            onClick={() => { setIdentity() }}
        >Logout</a>
    </div>)

    items.push(<hr />)

    items.push(<Link to='/'>Home</Link>)

    return <div>{items}</div>
}

export default AuthPage;
