import React from 'react';
import { Link } from 'react-router-dom'
import './App.css';

function App() {
  const [getCount, setCount] = React.useState(0)

  let header = []
  let body = []

  header.push(<div>PdxLu</div>)

  body.push(<div>Hello World 1</div>)

  body.push(
    <div className='mt-2'>
      <span className='mx-1'>{getCount}</span>
      <div className='btn btn-primary'
        onClick={() => { setCount(getCount + 1); }}>
        add
      </div>
    </div>
  )

  body.push(<div className='mt-2'>
    <Link to='auth'>Auth Page</Link>
    </div>)

  return (
    <div className="App">
      <div className="App-header">
        {header}
      </div>
      <div className="App-body">
        {body}
      </div>
    </div>
  );
}

export default App;
